<template>
  <el-col :span="12" @click="showDetail">
    <el-card shadow="always" class="room-item">
      <el-row>
        <el-col :span="21" class="row-inline">{{ name }}</el-col>
        <el-col :span="3" class="text-right">
          <span v-if="hasNewMessage" class="dot" />
        </el-col>
      </el-row>
      <!-- - {{ unreadCount }} -->
      <!-- <div>{{ updatedAt }}</div> -->
    </el-card>
  </el-col>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useEcho } from '@/functions/useEcho'
import router from '@/router'
// import moment from 'moment'
import 'moment/locale/ja'

export default defineComponent({
  name: 'RoomItem',
  props: {
    id: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    updatedAt: {
      type: String,
      required: true
    },
    unreadCount: {
      type: Number,
      default: 0
    }
  },

  setup(props) {
    const { echo } = useEcho()
    const hasNewMessage = ref(false)

    const showDetail = () => {
      router.push({ name: 'ChatRoomDetail', params: { id: props.id }})
    }

    onMounted(() => {
      echo.private('groups_' + props.id)
        .listen('MessageSent', () => {
          hasNewMessage.value = true
        })
    })

    return {
      showDetail,
      hasNewMessage
    }
  }
})
</script>
<style lang="scss" scoped>
.room-item {
  // padding: 30px 20px !important;
  background-color: #6ebde3;
  margin: 10px 0 10px 0;
  cursor: pointer;
  font-size: 20px;
  color: #173577;
}
.room-item:hover{
  background-color: #5faed4;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.text {
  font-size: 14px;
}
.item {
  margin-bottom: 18px;
}
.box-card {
  width: 480px;
}
.box-card:hover {
  cursor: pointer;
}
.time {
  font-size: 13px;
  color: #999;
}
.bottom {
  margin-top: 13px;
  line-height: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.button {
  padding: 0;
  min-height: auto;
}
.dot{
  height: 15px;
  width: 15px;
  background-color: #e4007b;
  border-radius: 50%;
  display:inline-block;
}

</style>
