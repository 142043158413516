<template>
  <div class="app-container">
    <h2 class="brand-color mb-20">チャット一覧</h2>
    <div v-loading="dataLoading" style="height: 100%">
      <div v-if="!rooms.length">
        データがありません
      </div>
      <el-row v-else :gutter="20" type="flex" style="justify-content:space-between">
        <room-item v-for="room in rooms" :id="room.id" :key="room.id" :name="room.name" :updated-at="room.updated_at" />
      </el-row>
    </div>
    <pagination
      :total="pager.total"
      :page="pager.page"
      :limit="pager.per_page"
      class="text-right"
      @pagination="fetchRooms"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, reactive } from 'vue'
import RoomItem from '@/views/chat/room/Item.vue'
import Pagination from '@/components/Pagination'
import { getRooms, ROOM_PER_PAGE } from '@/api/chat'

export default defineComponent({
  name: 'ChatIndex',
  components: {
    RoomItem,
    Pagination
  },

  setup() {
    const dataLoading = ref(false)
    const rooms = ref([])
    const pager = reactive({
      page: 1,
      per_page: ROOM_PER_PAGE,
      total: 0
    })

    const fetchRooms = (data) => {
      dataLoading.value = true
      if (data) {
        pager.page = data.page
        pager.per_page = data.limit
      }
      getRooms(pager).then(({ data }) => {
        rooms.value = data.data
        pager.total = data.total

        dataLoading.value = false
      })
    }

    onMounted(() => {
      fetchRooms()
    })

    return {
      rooms,
      pager,
      dataLoading,
      fetchRooms
    }
  }
})
</script>

<style lang="scss" scoped>
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>
